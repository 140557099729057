import React from "react";
import { useNavigate } from "react-router-dom";
import playlistIcon from "../../assets/playlist-white.svg";
import "./styles.scss";

const PlaylistThumbnail = ({
  noPhoto = true,
  playlist,
  clickHandled = false,
}) => {
  /*
  Thumbnail for a playlist.
  */

  const navigate = useNavigate();
  let isPhoto = false;
  let url = null;
  let type = null;

  if (!noPhoto) {
    if (playlist.video_count > 0) {
      isPhoto = true;
      url = playlist.thumbnail_url;
      type = playlist.thumbnail_type;
    }
  }

  const handleClick = () => {
    if (playlist.video_count > 0 && !clickHandled) {
      window.open(`/playlist/${playlist.object_id}`, "_blank");
    }
  };

  return (
    <div
      className={`playlist-image-container-container ${playlist?.video_count > 0 ? "playlist-image-container-exist" : ""}`}
      onClick={handleClick}
    >
      <div className="playlist-background"></div>
      <div className="playlist-image-container">
        {isPhoto ? (
          <img
            className="playlist-image"
            src={url}
            style={{
              objectFit: type === "landscape" ? "cover" : "contain",
            }}
          ></img>
        ) : (
          <div className="playlist-image-placeholder"></div>
        )}
        <div className="playlist-info-bar">
          <img src={playlistIcon}></img>
          {playlist?.video_count || "0"} Video
          {playlist?.video_count !== 1 && "s"}
        </div>
      </div>
    </div>
  );
};

export default PlaylistThumbnail;
