import React, { useEffect, useState } from "react";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { setUserVideoData } from "../../actions/data";
// import { useNavigate } from "react-router-dom";
// import AdBanner from "../../components/AdBanner/AdBanner";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent";
import { userService } from "../../services/user.service.ts";
import { playlistService } from "../../services/playlist.service.ts";
import ItemCard from "../../components/ItemCard/ItemCard.js";
import useWindowWidth from "../../hooks/useWindowWidth.js";

const LandingPage = () => {
  /*
  This component is the main entry point for the user's landing page.
  It fetches and displays videos and playlists in different categories,
  such as popular videos, staff picks, and user watch history. It dynamically displays videos and playlists
  based on user authentication and window width.

  Notes:
  - isSkeleton: a parameter that is used to display a skeleton loading animation while the component is loading.
  - A set of variables is created to track the loading progress of each row. Also, note that the logic for displaying
    different sections of the landing page is based on the user's authentication status.
  */

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const [staffPicksVideos, setStaffPicksVideos] = useState([]);
  const [staffPicksPlaylists, setStaffPicksPlaylists] = useState([]);
  const [publicPlaylists, setPublicPlaylists] = useState([]);
  const [watchHistory, setWatchHistory] = useState([]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verticalVideos, setVerticalVideos] = useState([]);
  const [horizontalVideos, setHorizontalVideos] = useState([]);
  const windowWidth = useWindowWidth();
  const skeletonItems = new Array(10).fill(null).map((_, index) => ({
    id: `${index}`,
    video_type: index === 0 ? "landscape" : "portrait",
  }));

  const [loadedPopularVideos, setLoadedPopularVideos] = useState(false);
  const [loadedFirstRow, setLoadedFirstRow] = useState(false);
  const [loadedWatchHistory, setLoadedWatchHistory] = useState(false);
  const [loadedPublicPlaylists, setLoadedPublicPlaylists] = useState(false);
  const [loadedPicks, setLoadedPicks] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getCategories = async () => {
      try {
        const response = await videoService.getCategories(true);
        if (isMounted) {
          setSelectedCategories(response);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getCategories();

    const getPopularVideos = async () => {
      try {
        const response = await videoService.getPopular();
        if (isMounted) {
          setPopularVideos(response);
          setLoadedPopularVideos(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPopularVideos();

    const fetchPublicPlaylists = async () => {
      try {
        const response = await playlistService.getPublicPlaylists();
        if (isMounted) {
          setPublicPlaylists(response);
          setLoadedPublicPlaylists(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPublicPlaylists();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // Track whether component is mounted

    const getFirstRow = async () => {
      try {
        const response = await videoService.getFirstRow();
        if (isMounted) {
          setVerticalVideos(response.vertical);
          setHorizontalVideos(response.horizontal);
          setLoadedFirstRow(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchPicksData = async () => {
      try {
        const response = await videoService.getStaffPicks();
        if (isMounted) {
          setStaffPicksVideos(response.videos);
          setStaffPicksPlaylists(response.playlists);
          setLoadedPicks(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchWatchHistory = async () => {
      try {
        const response = await userService.getWatchHistory();
        if (isMounted) {
          dispatch(setUserVideoData(response));
          try {
            let data = [];
            for (const item of response) {
              if (item.playlist) {
                const videoData = item.playlist;
                videoData.type = "playlist";
                videoData.current_video = item.video.id;
                videoData.thumbnail_url = item.video.thumbnail_url;
                videoData.thumbnail_type = item.video.video_type;
                data.push(videoData);
              } else {
                const videoData = item.video;
                videoData.type = "video";
                data.push(videoData);
              }
            }
            setWatchHistory(data);
            setLoadedWatchHistory(true);
          } catch (err) {
            console.error("Error setting Watch history:", err);
          }
        }
      } catch (error) {
        console.error("Error fetching user watch history:", error);
      }
    };

    if (isAuthenticated) {
      getFirstRow();
      fetchPicksData();
      fetchWatchHistory();
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated]);

  const category1Videos = () => {
    const category1 = selectedCategories[0];
    if (category1) {
      return popularVideos.filter((video) =>
        video.categories.some((category) => category.name === category1.name),
      );
    }
    return [];
  };
  const category1Playlists = () => {
    const category1 = selectedCategories[0];
    if (category1) {
      return publicPlaylists.filter((playlist) =>
        playlist.categories.some((category) => category === category1.name),
      );
    }
    return [];
  };
  const category2Videos = () => {
    const category2 = selectedCategories[1];
    if (category2) {
      return popularVideos.filter((video) =>
        video.categories.some((category) => category.name === category2.name),
      );
    }
    return [];
  };
  const category2Playlists = () => {
    const category2 = selectedCategories[1];
    if (category2) {
      return publicPlaylists.filter((playlist) =>
        playlist.categories.some((category) => category === category2.name),
      );
    }
    return [];
  };

  const isLoaded = (section = 3) => {
    switch (section) {
      case 1:
        return isAuthenticated
          ? loadedFirstRow && loadedWatchHistory && loadedPopularVideos
          : loadedPopularVideos && loadedPublicPlaylists;
      case 2:
      case 3:
        return isAuthenticated
          ? loadedPublicPlaylists &&
              loadedPicks &&
              loadedFirstRow &&
              loadedWatchHistory &&
              loadedPopularVideos
          : loadedPopularVideos && loadedPublicPlaylists;
      default:
        return false;
    }
  };

  return (
    <div className="landing-page-main">
      {isAuthenticated === false ? (
        // <div className="ad-banner-signup">
        //   <AdBanner isLogin={false} handleClick={() => navigate("/signup")} />
        // </div>
        <></>
      ) : isLoaded(1) ? (
        <div className="ad-banner-login">
          {verticalVideos.length >= 3 && horizontalVideos.length > 0 ? (
            <>
              <ItemCard
                object={horizontalVideos[0]}
                is_horizontal={true}
                is_banner={true}
                level="none"
              />
              {verticalVideos
                .slice(0, Math.max(0, ~~((windowWidth - 682) / 232)))
                .map((video, index) => {
                  return (
                    <ItemCard
                      object={video}
                      is_horizontal={true}
                      is_banner={true}
                      level="none"
                      key={index}
                    />
                  );
                })}
            </>
          ) : null}
        </div>
      ) : (
        <div className="ad-banner-login">
          {skeletonItems
            .slice(0, ~~((windowWidth - 682 + 232) / 232))
            .map((video, index) => {
              return (
                <ItemCard
                  object={video}
                  is_horizontal={true}
                  is_banner={true}
                  level="none"
                  key={index}
                  isSkeleton={true}
                />
              );
            })}
        </div>
      )}
      {isAuthenticated && (watchHistory.length > 0 || !loadedWatchHistory) && (
        <ListVideoComponent
          items={watchHistory}
          heading="Continue Watching"
          type="mixed"
          isSkeleton={!isLoaded(1)}
          key_prefix="watch-history-"
        />
      )}

      {(popularVideos.length > 0 || !loadedPopularVideos) && (
        <ListVideoComponent
          items={popularVideos}
          heading="Trending Videos"
          isSkeleton={!isLoaded(1)}
          key_prefix="trending-video-"
        />
      )}
      {(publicPlaylists.length > 0 || !loadedPublicPlaylists) && (
        <ListVideoComponent
          items={publicPlaylists}
          heading="Trending Playlists"
          type={"playlists"}
          isSkeleton={!isLoaded(2)}
          key_prefix="trending-playlist-"
        />
      )}
      {isAuthenticated && (staffPicksVideos.length > 0 || !loadedPicks) && (
        <ListVideoComponent
          items={staffPicksVideos}
          heading="Staff Picks - Videos"
          isSkeleton={!isLoaded(2)}
          key_prefix="staff-video-"
        />
      )}
      {isAuthenticated && (staffPicksPlaylists.length > 0 || !loadedPicks) && (
        <ListVideoComponent
          items={staffPicksPlaylists}
          heading="Staff Picks - Playlists"
          type={"playlists"}
          isSkeleton={!isLoaded(2)}
          key_prefix="staff-playlist-"
        />
      )}
      {selectedCategories.length > 0 && category1Videos().length > 0 && (
        <ListVideoComponent
          items={category1Videos()}
          heading={`Popular Videos in ${selectedCategories[0].name}`}
          isSkeleton={!isLoaded(3)}
          key_prefix="popular-video1-"
        />
      )}
      {selectedCategories.length > 0 && category1Playlists().length > 0 && (
        <ListVideoComponent
          items={category1Playlists()}
          heading={`Popular Playlists in ${selectedCategories[0].name}`}
          type={"playlists"}
          isSkeleton={!isLoaded(3)}
          key_prefix="popular-playlist1-"
        />
      )}
      {selectedCategories.length > 1 && category2Videos().length > 0 && (
        <ListVideoComponent
          items={category2Videos()}
          heading={`Popular Videos in ${selectedCategories[1].name}`}
          isSkeleton={!isLoaded(3)}
          key_prefix="popular-video2-"
        />
      )}
      {selectedCategories.length > 1 && category2Playlists().length > 0 && (
        <ListVideoComponent
          items={category2Playlists()}
          heading={`Popular Playlists in ${selectedCategories[1].name}`}
          type={"playlists"}
          isSkeleton={!isLoaded(3)}
          key_prefix="popular-playlist2-"
        />
      )}
    </div>
  );
};

export default LandingPage;
