import AuthorAvatar from "../AuthorAvatar/AuthorAvatar";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Skeleton } from "@mui/material";

const ChannelName = ({
  author,
  handleClose = () => {},
  isSkeleton = false,
  redirectToChannelPage = true,
}) => {
  /*
    Creates author's icon with the name of the author
    Alligned with latest Figma design (May 14) 
    */
  const navigate = useNavigate();
  const handleClick = () => {
    if (redirectToChannelPage) {
      navigate(`/channel/${author.username}`);
      handleClose();
    }
  };
  return (
    <>
      {author && !isSkeleton ? (
        <div className="channel" onClick={handleClick}>
          <div className="channel-logo">
            <AuthorAvatar
              author={author}
              redirectToChannelPage={redirectToChannelPage}
            />
          </div>
          <div className="channel-first-last-name">
            {author.channel_name && author.channel_name?.length > 0
              ? author.channel_name
              : author.first_name + " " + author.last_name}
          </div>
        </div>
      ) : null}
      {isSkeleton && (
        <div className="channel">
          <div className="channel-logo">
            <AuthorAvatar author={author} isSkeleton={isSkeleton} />
          </div>
          <div className="channel-first-last-name">
            <Skeleton variant="text" width="30%" />
          </div>
        </div>
      )}
    </>
  );
};

export default ChannelName;
