import React from "react";
import plus from "../../assets/plus.svg";
import bluePlus from "../../assets/blue-plus.svg";
import upload from "../../assets/upload.svg";
import checkmarkBlack from "../../assets/checkmark-black.svg";
import "./styles.scss";

const ButtonComponent = ({
  text,
  handleClick,
  level = "primary",
  size = "medium",
  icon = true,
  uploadIcon = false,
  checkmarkIcon = false,
  noBorders = false,
  coverFullWidth = false,
  isPink = false,
}) => {
  /*
    Creates a simple button with text inside with or without an icon
    Alligned with latest Figma design (May 14)
    icon: true, false
    level: "primary", "secondary", "ghost", "disable": all of them work
    sizes: "medium", "small": ONLY medium works
    noBoders: true, false: special case for the footer (ignore for others)
    coverFullWidth: a special styling when buttons covers 100% width of its parent
    handleClick: callback function for button click
    isPink: a special styling for button to be pink
    uploadIcon, checkmarkIcon: special cases for the upload and checkmark icons
    */

  let iconImage = plus;
  if (uploadIcon) {
    iconImage = upload;
  }
  if (checkmarkIcon) {
    iconImage = checkmarkBlack;
  }

  if (level === "ghost") {
    iconImage = bluePlus;
  }

  return (
    <button
      className={`button-icon-component-basic-${level} button-${size} ${noBorders ? "no-borders" : ""} ${coverFullWidth ? "full-width" : ""} ${isPink ? "pink" : ""}`}
      onClick={handleClick}
      disabled={level === "disable"}
    >
      {icon && (
        <div>
          <img src={iconImage} alt="icon" />
        </div>
      )}
      <div id="button-icon-basic-text">{text}</div>
    </button>
  );
};

export default ButtonComponent;
