import React, { useState, useEffect } from "react";
import eyeIcon from "../../assets/eye.svg";
import eyeSlashIcon from "../../assets/eye-slash.svg";
import "./styles.scss";
import TooltipComponent from "../TooltipComponent/TooltipComponent";

const TextInputComponent = ({
  label,
  handleInput,
  placeholder,
  initialValue = "",
  required = false,
  eye = false,
  disabled = false,
  restriction = false,
  toolTip = false,
  toolTipText = "",
}) => {
  /*
    getting state as a prop only matter if it's one of the following: disable, invalid, validated, helper
    Also, note, that it's not finished component. Make sure that it works for your inputs
    handleInput: callback prop function that handles input change
    Fully alligned with Figma design (May 15)
    */

  const [inputValue, setInputValue] = useState(initialValue);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [currentEyeIcon, setCurrentEyeIcon] = useState(eyeIcon);

  const handleInputChange = (e) => {
    if (eyeClicked) {
      if (e.target.value.length < inputValue.length) {
        setInputValue(inputValue.slice(0, e.target.value.length));
      } else {
        setInputValue(
          inputValue +
            e.target.value.slice(inputValue.length, e.target.value.length)
        );
      }
      const syntheticEvent = {
        ...e,
        target: {
          ...e.target,
          value: inputValue,
        },
      };
      handleInput(syntheticEvent);
    } else {
      handleInput(e);
      if (label === "Username") {
        setInputValue(
          e.target.value
            .slice(0, 15)
            .toLowerCase()
            .replace(/[^a-z0-9_]/g, "")
        );
      } else {
        setInputValue(e.target.value);
      }
    }
  };

  const handleEyeClick = () => {
    setEyeClicked(!eyeClicked);
    setCurrentEyeIcon(eyeClicked ? eyeIcon : eyeSlashIcon);
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <div className="text-input-component">
      <div className="label">
        <div>
          {label}
          {toolTip && (
            <TooltipComponent
              tipText={toolTipText}
              longText={toolTipText.length > 50}
            />
          )}
        </div>
        <div className="star">{required ? " *" : ""}</div>
      </div>
      <div className="text-input-component-bottom">
        <div className="input">
          <input
            type="text"
            onChange={handleInputChange}
            className="text-input-component-input"
            placeholder={placeholder}
            value={eyeClicked ? "•".repeat(inputValue.length) : inputValue}
            disabled={disabled}
          />
          {eye && (
            <div className="eye" onClick={handleEyeClick}>
              <img src={currentEyeIcon} alt="eye" />
            </div>
          )}
          {restriction && (
            <div
              className={`char-count ${inputValue.length > 100 ? "long" : "normal"}`}
            >
              {inputValue.length}/100
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextInputComponent;
