import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

function TagsUpdated({ tags, filterTags }) {
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);

  useEffect(() => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    let currentWidth = 0;
    const newVisibleTags = [];

    tags.forEach((tag) => {
      const tagWidth = document.createElement("div");
      tagWidth.className = "single-tag";
      tagWidth.style.visibility = "hidden";
      tagWidth.innerText = `#${tag.name ? tag.name : tag}`;
      containerRef.current.appendChild(tagWidth);

      if (currentWidth + tagWidth.offsetWidth + 8 <= containerWidth) {
        currentWidth += tagWidth.offsetWidth + 8;
        newVisibleTags.push(tag);
      }

      containerRef.current.removeChild(tagWidth);
    });

    setVisibleTags(newVisibleTags);
  }, [tags]);

  return (
    <div
      ref={containerRef}
      className={`tags-updated ${!filterTags ? "detail-mode" : ""}`}
    >
      {filterTags
        ? visibleTags.map((tag, index) => (
            <div key={index} className="single-tag">
              {`#${tag.name ? tag.name : tag}`}
            </div>
          ))
        : tags.map((tag, index) => (
            <div key={index} className="single-tag">
              {`#${tag.name ? tag.name : tag}`}
            </div>
          ))}
    </div>
  );
}

export default TagsUpdated;
