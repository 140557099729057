import { ApiService } from "./api.service.ts";

class PlaylistService {
  async getPlaylist(
    playlistId: string,
    preview: boolean = false
  ): Promise<any> {
    return await ApiService.get(`/playlist/${playlistId}/?preview=${preview}`);
  }

  async getPublicPlaylists(): Promise<any> {
    return await ApiService.get("/playlists/public/");
  }

  async getUserPlaylists(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/playlists/`);
  }

  async generatePlaylist(query: string): Promise<any> {
    return await ApiService.post(`/search/ai/playlist`, { query });
  }

  async createPlaylist(
    title: string,
    visibility: string,
    description: string,
    videos: string[],
    ai_generated: boolean = false
  ): Promise<any> {
    const requestData: any = {
      title,
      visibility,
      description,
      videos,
      ai_generated,
    };

    return await ApiService.post("/playlists/create/", requestData);
  }

  async addVideoToPlaylist(videoId: string, playlistId: string): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_video/`, {
      video_id: videoId,
    });
  }

  async addPlaylistToPlaylist(
    childPlaylistId: string,
    playlistId: string
  ): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_playlist/`, {
      child_playlist_id: childPlaylistId,
    });
  }

  async editPlaylist(
    playlistId: string,
    title: string,
    visibility: string,
    description: string
  ): Promise<any> {
    return await ApiService.post(`/playlist/edit-playlist/${playlistId}/`, {
      title,
      visibility,
      description,
    });
  }

  async updatePlaylistStructure(
    data: any,
    currentItemId?: string
  ): Promise<any> {
    return await ApiService.post("/playlists/update-playlist-structure/", {
      data,
      current_item_id: currentItemId,
    });
  }

  async deleteFromPlaylist(objectId: string): Promise<any> {
    return await ApiService.delete(`/playlist/item/${objectId}/remove/`);
  }

  async deleteVideoFromPlaylist(
    playlistId: string,
    videoId: string
  ): Promise<any> {
    return await ApiService.delete(
      `/playlist/${playlistId}/video/${videoId}/remove/`
    );
  }

  async deletePlaylist(playlistId: string): Promise<any> {
    return await ApiService.delete(`/playlists/${playlistId}/delete/`);
  }

  async duplicatePlaylist(itemId: string, parentId: string): Promise<any> {
    return await ApiService.post(
      `/playlist/duplicate-and-add/${itemId}/${parentId}/`
    );
  }

  async changePlaylistVisibility(
    playlistId: string,
    visibility: string
  ): Promise<any> {
    return await ApiService.post(`/playlist/change-visibility/${playlistId}/`, {
      visibility,
    });
  }
}

export const playlistService = new PlaylistService();
