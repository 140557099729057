import React, { useState, useEffect } from "react";
import privateIcon from "../../assets/private.svg";
import publicIcon from "../../assets/public.svg";
import unlistedIcon from "../../assets/unlisted.svg";
import checkmark from "../../assets/checkmark.svg";
import trash from "../../assets/trash.svg";
import watch from "../../assets/watch.svg";
import remove from "../../assets/remove.svg";
import share from "../../assets/share.svg";
import edit from "../../assets/edit.svg";
import duplicate from "../../assets/duplicate.svg";
import "./styles.scss";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown";

const DropdownMenuPlaylistComponent = ({
  type,
  chosenOption,
  handleChange,
  categories = [],
  handleClose = () => {},
  isRightAlignedProp = false,
  isPlaylistTree = false,
  noPhoto = false,
}) => {
  /*
    Creates a dropdown menu with a headline and a list of options
    Alligned with latest Figma design (May 14)
    type must be: "privacy", "menu", "video", or "news"
    modify 'options', so that it correctly displays the options for each type
    Note: created another type "categories" used to chose categories for a video (it's not on Figma)
    Note: categories prop is not used for other types

    handleClose: a callback function to close the dropdown menu
    isPlaylistTree: a boolean prop to determine if the dropdown is for the playlist tree
    
    Todo extension: we can implement this component a bit better, and for better reusability. We can have the 
    component take attributes as: headline, options, selected, handleChange, and maybe multiple=false 
    (to differentiate the dropdown for the privacy settings and the learning outcomes.To account for the icons,
    we could just have options be of the form: options = [{value: ..., icon: ...}, ...] for an option menu with icons, 
    and just [{value: ...}, ...] for an option menu without icons. Then for an option, we could just check if option.icon exists.
    */
  const [rightAligned, setRightAligned] = useState(isRightAlignedProp);

  useEffect(() => {
    setRightAligned(isRightAlignedProp);
  }, [isRightAlignedProp]);

  const lists = {
    privacy: {
      headline: "Privacy Settings",
      options: [
        { label: "public", icon: publicIcon },
        { label: "unlisted", icon: unlistedIcon },
        { label: "private", icon: privateIcon },
      ],
    },
    menu: {
      headline: "Menu",
      options: [
        { label: "edit", icon: edit },
        { label: "watch", icon: watch },
        { label: "duplicate", icon: duplicate },
        { label: "share", icon: share },
        { label: "delete", icon: trash },
      ],
    },
    video: {
      headline: "Video Actions",
      options: [
        { label: "watch", icon: watch },
        { label: "share", icon: share },
        { label: "remove", icon: remove },
      ],
    },
    news: {
      headline: "Options",
      options: [
        { label: "Frequently", icon: null },
        { label: "Occasionally", icon: null },
        { label: "Never", icon: null },
      ],
    },
    category: {
      headline: "Categories",
      options: [],
    },
  };

  const currentList = lists[type];

  if (type === "category") {
    categories.map((category) => {
      currentList.options.push({ label: category, icon: "" }); // icon is not used for categories, but we can add it later
    });
  }

  const toFirstLetterUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div
      className={`dropdown-menu-playlist-container ${rightAligned === true ? "drop-menu-right-container" : ""} ${isPlaylistTree ? "dropdown-playlist-tree-container" : ""}`}
    >
      <AnimatedDropdown
        className={`dropdown-menu-playlist-component ${isPlaylistTree ? "dropdown-playlist-tree" : ""}`}
        closeFunc={() => handleClose()}
      >
        <div className="dropdown-headline">{currentList.headline}</div>
        <div className="dropdown-list-container">
          <ul className="dropdown-list">
            {currentList.options.map((option) => (
              <li
                key={option.label}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(option.label);
                }}
                className="dropdown-list-item"
              >
                <div className="dropdown-list-item-left">
                  {type !== "category" ? (
                    <div
                      className={`dropdown-list-option-icon${noPhoto ? " no-photo" : ""}`}
                    >
                      {!noPhoto && <img src={option.icon} />}
                    </div>
                  ) : null}
                  <div className="dropdown-list-option-name">
                    {toFirstLetterUpperCase(option.label)}
                  </div>
                </div>
                {chosenOption.includes(option.label) &&
                  (type === "privacy" || type === "news") && (
                    <div className="dropdown-list-option-checkmark">
                      <img src={checkmark} />
                    </div>
                  )}
              </li>
            ))}
          </ul>
        </div>
      </AnimatedDropdown>
    </div>
  );
};

export default DropdownMenuPlaylistComponent;
