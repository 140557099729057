import { Cookie } from "../utils/cookie.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";
import { jwtDecode } from "jwt-decode";

export function RequestInterceptorResolve(config: any) {
  const token =
    Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) || localStorage.getItem("access");

  // Check if token is valid and not expired
  if (token) {
    const decoded = jwtDecode(token);
    // Check if token is invalid or expired
    if (!decoded.exp || decoded.exp < Date.now() / 1000) {
      Cookie.Remove(AUTH_TOKEN.ACCESS_TOKEN);
      localStorage.removeItem("access");
    } else {
      config.headers!["Authorization"] = `JWT ${token}`;
    }
    // config.headers!['access-token'] = `${token}`
  }
  return config;
}

export function RequestInterceptorReject(error: any) {
  return Promise.reject(error);
}
