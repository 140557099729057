import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import authService from "../services/auth.service.ts";
import { Cookie } from "../utils/cookie.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";
import { AUTHENTICATED_FAIL, AUTHENTICATED_SUCCESS } from "../actions/types";
import { useNavigate } from "react-router-dom";
import { load_user } from "../actions/auth.js";

const PrivatePage = ({ children, guestAccessible = false }) => {
  /* 
    Determines logic for pages that shouldn't be accessed by non-authenticated users.
  */

  // Access the isAuthenticated part of the state
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestCheckJwtToken = async () => {
    try {
      const accessToken =
        Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) || localStorage.getItem("access");
      if (accessToken) {
        const response = await authService.checkJwtToken({
          token: accessToken,
        });
        if (response.code !== "token_not_valid") {
          dispatch({ type: AUTHENTICATED_SUCCESS });
          dispatch(load_user());
        } else {
          !guestAccessible && navigate("/login");
          dispatch({ type: AUTHENTICATED_FAIL });
        }
      } else {
        !guestAccessible && navigate("/login");
        dispatch({ type: AUTHENTICATED_FAIL });
      }
    } catch (e) {
      !guestAccessible && navigate("/login");
      dispatch({ type: AUTHENTICATED_FAIL });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      // return <Navigate to="/login" />;
      requestCheckJwtToken();
    }
  }, []);

  return loading ? null : children;
};

export default PrivatePage;
