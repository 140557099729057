import React from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";

function Tabs({ tabs, defaultTab = "MyContent" }) {
    /*
    Corresponds to the latest Figma design (May 15)
    todo: Modify the component to have "disable" option for tabs and create a prop for it
    MyContent is the default tab but you might need to create a separate prop for that
    */

    const location = useLocation();  
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab') || defaultTab; 

    const setParamsUrl = (newTab) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('tab', newTab);
        window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
        window.dispatchEvent(new PopStateEvent('popstate')); 
    };

    return (
        <div className="tabs-list">
            {tabs.map((currentTab) => (
                <button
                    key={currentTab}
                    className={`tab-button ${tab === currentTab ? 'active' : ''}`}
                    onClick={() => setParamsUrl(currentTab)}>
                    {currentTab.replace(/([A-Z])/g, ' $1').trim()}
                </button>
            ))}
        </div>
    );
}

export default Tabs;
