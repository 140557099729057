// Auth Reducers

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const GITHUB_AUTH_SUCCESS = "GITHUB_AUTH_SUCCESS";
export const GITHUB_AUTH_FAIL = "GITHUB_AUTH_FAIL";
export const LOGOUT = "LOGOUT";

// Data Reducers
export const SET_DATA = "SET_DATA";
export const SET_USER_VIDEO_DATA = "SET_USER_VIDEO_DATA";

// Video Reducers
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_VIDEO_PLAYER_REF = "SET_VIDEO_PLAYER_REF";
export const SET_VIDEO_PLAYER_REFS = "SET_VIDEO_PLAYER_REFS";
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO";

// Card Reducers
export const SET_CURRENT_CARD = "SET_CURRENT_CARD";

// Playlist Reducers
export const FETCH_PLAYLISTS_REQUEST = "FETCH_PLAYLISTS_REQUEST";
export const FETCH_PLAYLISTS_SUCCESS = "FETCH_PLAYLISTS_SUCCESS";
export const FETCH_PLAYLISTS_FAILURE = "FETCH_PLAYLISTS_FAILURE";

export const CREATE_PLAYLIST_SUCCESS = "CREATE_PLAYLIST_SUCCESS";
export const CREATE_PLAYLIST_FAILURE = "CREATE_PLAYLIST_FAILURE";

export const SET_CURRENT_PLAYLIST = "SET_CURRENT_PLAYLIST";
export const SET_PLAYLISTS = "SET_PLAYLISTS";
export const SET_WATCHING_PLAYLIST = "SET_WATCHING_PLAYLIST";

export const UPDATE_PLAYLIST_NAME = "UPDATE_PLAYLIST_NAME";
export const UPDATE_PLAYLIST_VISIBILITY = "UPDATE_PLAYLIST_VISIBILITY";

export const NOTIFY_SUCCESS = "NOTIFY_SUCCESS";
export const NOTIFY_ERROR = "NOTIFY_ERROR";
export const NOTIFY_WARNING = "NOTIFY_WARNING";
export const NOTIFY_INFO = "NOTIFY_INFO";
export const CLEAR_TOAST_MESSAGE = "CLEAR_TOAST_MESSAGE";

export const ADD_LIKE = "ADD_LIKE";
export const REMOVE_LIKE = "REMOVE_LIKE";

export const ADD_VIDEO_LIKE = "ADD_VIDEO_LIKE";
export const REMOVE_VIDEO_LIKE = "REMOVE_VIDEO_LIKE";

export const UPDATE_NOTE_CONTENT = "UPDATE_NOTE_CONTENT";

export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";

// Global Reducers
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SHOW_SIGNUP = "SHOW_SIGNUP";
export const HIDE_LOGIN = "HIDE_LOGIN";
export const HIDE_SIGNUP = "HIDE_SIGNUP";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SET_VIDEO_SPEED = "SET_VIDEO_SPEED";
export const SET_AUDIO_LEVEL = "SET_AUDIO_LEVEL";
export const SET_MUTE = "SET_MUTE";
export const SET_AUTOPLAY = "SET_AUTOPLAY";
