import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import VideoItem from "../VideoItem/VideoItem";
import SearchBarComponent from "../SearchBarComponent/SearchBarComponent.js";
import PlaylistItem from "../PlaylistItem/PlaylistItem";
import Pagination from "@mui/material/Pagination";
import CrossSign from "../../assets/cross-sign.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import VideoPreview from "../VideoPreview/VideoPreview.js";
import Spinner from "../Spinner/Spinner.js";
import Popup from "../../containers/Popup/Popup.js";
import { hideModal } from "../../actions/global.action";

const PAGE_SIZE = 20;

const AddVideoToPlaylistPopOut = () => {
  /*
    Pop out window to add a video/nested playlist to a 'parent' playlist.
    Accessible via Dashboard > Playlists and /playlists.
    Opens a window when clicked on + in any playlist or 'Add items' in Playlist preview.

    Three tabs available:
      - Videos
      - Playlists
      - My favorites
  */

  // Accessing video data from the Redux store
  const dispatch = useDispatch();
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const [queryResults, setQueryResults] = useState();
  const [queryTerm, setQueryTerm] = useState("");
  const [category, setCategory] = useState("videos");
  const [activeTab, setActiveTab] = useState("videos");
  const [currentPlaylistPage, setCurrentPlaylistPage] = useState(0);
  const [currentVideoPage, setCurrentVideoPage] = useState(0);
  const [playlistPagesCount, setPlaylistPagesCount] = useState(0);
  const [videoPagesCount, setVideoPagesCount] = useState(0);
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const addRef = useRef(null);
  useOutsideClick(addRef, () => setClosePopup(true));
  const [closePopup, setClosePopup] = useState(false);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handlePlaylistPaginationChange = (ev, page) => {
    setCurrentPlaylistPage(page - 1);
  };

  const handleVideoPaginationChange = (ev, page) => {
    setCurrentVideoPage(page - 1);
  };

  const handleQuery = (page_change = false) => {
    setLoading(true);
    const currentPage = page_change
      ? category === "videos"
        ? currentVideoPage
        : currentPlaylistPage
      : 0;
    videoService
      .search(queryTerm, category, currentPage)
      .then((response) => {
        setQueryResults(response);
      })
      .catch((error) => {
        console.error("Search error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const query_term = "";
    videoService
      .search(query_term, category, 0)
      .then((response) => {
        setQueryResults(response);
      })
      .catch((error) => {
        console.error("Search error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    handleQuery();
    setCurrentPlaylistPage(0);
    setCurrentVideoPage(0);
  }, [queryTerm, category]);

  useEffect(() => {
    handleQuery(true);
  }, [currentPlaylistPage, currentVideoPage]);

  useEffect(() => {
    setPlaylistPagesCount(
      Math.ceil(queryResults?.statistics?.playlists / PAGE_SIZE),
    );
    setVideoPagesCount(Math.ceil(queryResults?.statistics?.videos / PAGE_SIZE));
  }, [queryResults]);

  return (
    <Popup
      className="video-add-wrapper"
      closePopup={closePopup}
      closeFunc={handleClose}
      withContainer={true}
    >
      <div className="video-add-form popup" ref={addRef}>
        {videoPreview !== null ? (
          <VideoPreview
            videoPreview={videoPreview}
            setVideoPreview={setVideoPreview}
            popout={true}
            handleClose={() => setClosePopup(true)}
          />
        ) : (
          <>
            <div className="video-add-title">
              Add Items
              <div className="playlists-search">
                <SearchBarComponent
                  placeholder={"Search the item name"}
                  handleChange={(e) => {
                    setQueryTerm(e.target.value?.toLowerCase());
                  }}
                  setIsFocused={() => {}}
                  handleClose={() => {
                    setQueryTerm("");
                  }}
                />
              </div>
              <button
                className="video-add-cross-sign"
                onClick={() => setClosePopup(true)}
              >
                <img src={CrossSign} alt="X" />
              </button>
            </div>
            <div className="add-content">
              <div className="video-add-tabs">
                <button
                  className={`search-tab-button ${activeTab === "videos" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("videos");
                    setCategory("videos");
                  }}
                >
                  Videos
                </button>
                <button
                  className={`search-tab-button ${activeTab === "playlists" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("playlists");
                    setCategory("playlists");
                  }}
                >
                  Playlists
                </button>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                    width: "100%",
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                queryResults && (
                  <>
                    <div className="video-list">
                      {/* Conditional rendering based on activeTab */}
                      <div>
                        {activeTab === "videos" && (
                          <div>
                            {queryResults?.videos?.map((video) => (
                              // Render each video
                              <VideoItem
                                key={video.id}
                                video={video}
                                playlist={currentPlaylist}
                                preview={setVideoPreview}
                              />
                            ))}
                          </div>
                        )}
                        {activeTab === "playlists" && (
                          <div>
                            <div>
                              {queryResults?.playlists
                                ?.filter(
                                  (pl) => pl.id !== currentPlaylist?.object_id,
                                )
                                .map((playlist) => (
                                  // Render each playlist
                                  <PlaylistItem
                                    key={playlist.id}
                                    item={playlist}
                                    playlist={currentPlaylist}
                                    setClosePopup={setClosePopup}
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {activeTab === "videos" && videoPagesCount > 1 && (
                      <div className="pagination">
                        <Pagination
                          count={videoPagesCount}
                          color="primary"
                          onChange={handleVideoPaginationChange}
                          page={currentVideoPage + 1}
                        />
                      </div>
                    )}

                    {activeTab === "playlists" && playlistPagesCount > 1 && (
                      <div className="pagination">
                        <Pagination
                          count={playlistPagesCount}
                          color="primary"
                          onChange={handlePlaylistPaginationChange}
                          page={currentPlaylistPage + 1}
                        />
                      </div>
                    )}
                  </>
                )
              )}
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AddVideoToPlaylistPopOut;
