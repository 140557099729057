import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../actions/types";
import authService from "../../services/auth.service.ts";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useSelector } from "react-redux";
import HamburgerItem from "../HamburgerItem/HamburgerItem";
import "./styles.scss";

import contact from "../../assets/contact.svg";
import edgurLogoIcon from "../../assets/edgur-logo-icon.png";
import edgurLogoText from "../../assets/edgur-logo-text.svg";
import favorites from "../../assets/favorites.svg";
import notes from "../../assets/notes.svg";
import hamburger from "../../assets/hamburger.svg";
import home from "../../assets/home.svg";
import logOut from "../../assets/log-out.svg";
import playlists from "../../assets/playlists.svg";
import privacy from "../../assets/privacy.svg";
import settings from "../../assets/settings.svg";
import star from "../../assets/star.svg";
import statistics from "../../assets/statistics.svg";
import topCreators from "../../assets/top-creators.svg";
import trending from "../../assets/trending.svg";
import uploads from "../../assets/uploads.svg";
import watchHistory from "../../assets/watch-history.svg";
import feed from "../../assets/feed.svg";
import login from "../../assets/login.svg";
import signup from "../../assets/signup.svg";

const navItems = [
  { label: "Home", icon: home, path: "/" },
  { label: "Sign up", icon: signup, path: "/signup" },
  { label: "Sign in", icon: login, path: "/login" },
  { label: "Feed", icon: feed, path: "/feed" },
  { label: "Uploads", icon: uploads, path: "/dashboard/?tab=Uploads" },
  { label: "Playlists", icon: playlists, path: "/dashboard/?tab=Playlists" },
  {
    label: "Watch history",
    icon: watchHistory,
    path: "/dashboard/?tab=WatchHistory",
  },
  { label: "Favorites", icon: favorites, path: "/dashboard/?tab=Favorites" },
  { label: "Notes", icon: notes, path: "/dashboard/?tab=Notes" },
  { label: "Top creators", icon: topCreators, path: "/top-creators" },
  { label: "Popular playlists", icon: star, path: "/popular-playlists" },
  {
    label: "Best of statistics",
    icon: statistics,
    path: "/best-of-statistics",
  },
  { label: "Trending videos", icon: trending, path: "/trending-videos" },
  { label: "Contact us", icon: contact, path: "/contact-us" },
  { label: "Privacy", icon: privacy, path: "/privacy" },
  { label: "Settings", icon: settings, path: "/settings" },
  { label: "Log out", icon: logOut, path: "/", action: "logout" },
];

const HamburgerMenu = ({ closeMenu }) => {
  /*
        Navigation bar on the left side of the website with website logo, name and some links.
        Alligned with Figma design (May 23). Two minor things left to do
        todo: when icon is selected, it should change its color to black
        optimization todo: the items can be stored as an object, the properties of which we should refer to
    */
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const hamburgerRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isScrolling, setIsScrolling] = useState(false);
  const timeoutIdRef = useRef(null);
  useOutsideClick(hamburgerRef, () => setIsClosing(true));

  useEffect(() => {
    const path = location.pathname + location.search;
    const item = navItems.find((item) => item.path === path);
    setSelectedItem(item ? item.label : null);
  }, [location]);

  const handleClick = (itemLabel) => {
    setSelectedItem(itemLabel);
    const item = navItems.find((navItem) => navItem.label === itemLabel);
    if (item) {
      if (item.action === "logout") {
        logout_user();
      } else {
        setIsClosing(true);
        navigate(item.path);
      }
    }
  };

  useEffect(() => {
    if (!hamburgerRef.current) return;

    const onScroll = () => {
      setIsScrolling(true);
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => setIsScrolling(false), 1000);
    };

    const menuElement = hamburgerRef.current;
    menuElement.addEventListener("scroll", onScroll);

    return () => {
      if (menuElement) {
        menuElement.removeEventListener("scroll", onScroll);
      }
      clearTimeout(timeoutIdRef.current);
    };
  }, [hamburgerRef]);

  const logout_user = async () => {
    setIsClosing(true);
    navigate("/");
    const res = await authService.logout();
    res && dispatch({ type: LOGOUT });
  };

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        closeMenu();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isClosing, closeMenu]);

  const unauthenticatedBlock = (
    <div className={`hamburger-menu-container ${isClosing ? "closing" : ""}`}>
      <div
        className={`hamburger-menu${isClosing ? " closing" : ""}${isScrolling ? " scrolling" : ""}`}
        ref={hamburgerRef}
      >
        <div className="hamburger-header">
          <img
            src={hamburger}
            className="hamburger-header-bars"
            onClick={() => setIsClosing(true)}
          />
          <div className="hamburger-header-logo" onClick={() => navigate("/")}>
            <img src={edgurLogoIcon} className="hamburger-header-logo-icon" />
            <img src={edgurLogoText} className="hamburger-header-logo-text" />
          </div>
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">HOME</div>
          <HamburgerItem
            icon={home}
            textToDisplay="Home"
            selected={selectedItem === "Home"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={feed}
            textToDisplay="Feed"
            selected={selectedItem === "Feed"}
            handleClick={handleClick}
          />
        </div>

        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">AUTHENTICATION</div>
          <HamburgerItem
            icon={login}
            textToDisplay="Sign in"
            selected={selectedItem === "Sign in"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={signup}
            textToDisplay="Sign up"
            selected={selectedItem === "Sign up"}
            handleClick={handleClick}
          />
        </div>

        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">EXPLORE</div>
          <HamburgerItem
            icon={topCreators}
            textToDisplay="Top creators"
            selected={selectedItem === "Top creators"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={star}
            textToDisplay="Popular playlists"
            selected={selectedItem === "Popular playlists"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={statistics}
            textToDisplay="Best of statistics"
            selected={selectedItem === "Best of statistics"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={trending}
            textToDisplay="Trending videos"
            selected={selectedItem === "Trending videos"}
            handleClick={handleClick}
          />
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">ABOUT</div>
          <HamburgerItem
            icon={contact}
            textToDisplay="Contact us"
            selected={selectedItem === "Contact us"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={privacy}
            textToDisplay="Privacy"
            selected={selectedItem === "Privacy"}
            handleClick={handleClick}
          />
        </div>

        <div className="hamburger-footer">2024 Edgur. All rights reserved.</div>
      </div>
    </div>
  );

  if (!isAuthenticated) return unauthenticatedBlock;

  return (
    <div className={`hamburger-menu-container ${isClosing ? "closing" : ""}`}>
      <div
        className={`hamburger-menu${isClosing ? " closing" : ""}${isScrolling ? " scrolling" : ""}`}
        ref={hamburgerRef}
      >
        <div className="hamburger-header">
          <img
            src={hamburger}
            className="hamburger-header-bars"
            onClick={() => setIsClosing(true)}
          />
          <div className="hamburger-header-logo" onClick={() => navigate("/")}>
            <img src={edgurLogoIcon} className="hamburger-header-logo-icon" />
            <img src={edgurLogoText} className="hamburger-header-logo-text" />
          </div>
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">HOME</div>
          <HamburgerItem
            icon={home}
            textToDisplay="Home"
            selected={selectedItem === "Home"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={feed}
            textToDisplay="Feed"
            selected={selectedItem === "Feed"}
            handleClick={handleClick}
          />
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">DASHBOARD</div>
          <HamburgerItem
            icon={uploads}
            textToDisplay="Uploads"
            selected={selectedItem === "Uploads"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={playlists}
            textToDisplay="Playlists"
            selected={selectedItem === "Playlists"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={watchHistory}
            textToDisplay="Watch history"
            selected={selectedItem === "Watch history"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={favorites}
            textToDisplay="Favorites"
            selected={selectedItem === "Favorites"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={notes}
            textToDisplay="Notes"
            selected={selectedItem === "Notes"}
            handleClick={handleClick}
          />
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">EXPLORE</div>
          <HamburgerItem
            icon={topCreators}
            textToDisplay="Top creators"
            selected={selectedItem === "Top creators"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={star}
            textToDisplay="Popular playlists"
            selected={selectedItem === "Popular playlists"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={statistics}
            textToDisplay="Best of statistics"
            selected={selectedItem === "Best of statistics"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={trending}
            textToDisplay="Trending videos"
            selected={selectedItem === "Trending videos"}
            handleClick={handleClick}
          />
        </div>
        <div className="hamburger-chapter">
          <div className="hamburger-chapter-title">ABOUT</div>
          <HamburgerItem
            icon={contact}
            textToDisplay="Contact us"
            selected={selectedItem === "Contact us"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={privacy}
            textToDisplay="Privacy"
            selected={selectedItem === "Privacy"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={settings}
            textToDisplay="Settings"
            selected={selectedItem === "Settings"}
            handleClick={handleClick}
          />
          <HamburgerItem
            icon={logOut}
            textToDisplay="Log out"
            selected={selectedItem === "Log out"}
            handleClick={handleClick}
          />
        </div>
        <div className="hamburger-footer">2024 Edgur. All rights reserved.</div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
