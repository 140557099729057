import React, { useState, useRef, useLayoutEffect } from "react";
import ShareModule from "../ShareModule/ShareModule";
import shareBlack from "../../assets/share-black.svg";
import shareWhite from "../../assets/share-white.svg";
import "./styles.scss";

const ShareDropdown = ({ idToShare, horizontal = false }) => {
  /*
  one of the components of toolbar comonnet; ideally it should be incorporated into the toolbar component
  that will have all the icons 
  corresponds to Figma design (May 15)
  */

  const [showShare, setShowShare] = useState(false);
  const dropdownRef = useRef(null);
  const [isRightAligned, setIsRightAligned] = useState(false);

  const adjustDropdownPosition = () => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const rightEdgeSpace = windowWidth - dropdownRect.right;

      if (rightEdgeSpace < 380) {
        setIsRightAligned(true);
      }
    }
  };

  useLayoutEffect(() => {
    adjustDropdownPosition();
    const handleResize = () => adjustDropdownPosition();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        left: "0 !important",
      }}
    >
      <div className="label-option">
        <button
          className={`sidebar-btn ${showShare && "btn-selected"}`}
          onClick={() => setShowShare(!showShare)}
        >
          <img
            src={showShare ? shareWhite : shareBlack}
            alt="share"
            ref={dropdownRef}
          />
        </button>
        <p className="sidebar-text">Share</p>
      </div>
      {showShare && (
        <ShareModule
          typeToShare="video"
          idToShare={idToShare}
          setShowShare={setShowShare}
          setShowDropdown={setShowShare}
          isRightAlign={isRightAligned}
        />
      )}
    </div>
  );
};

export default ShareDropdown;
