import React from "react";
import "./styles.scss";
import noVideo from "../../assets/no-video.svg";

const ThumbnailComponent = ({ video, thumbnail=null }) => {
  /*
    Thumbnail component alligns with Figma design (May 15)
    Note: current impolementation doesn't have heart symbol, sybject text and blue bar 
    There is another Card component that duplicates some of this component functionality 
    and thus should be deleted and replayced with this component
    */
  return (
    <div className="video-image-container">
      <img
        className="video-image"
        src={thumbnail?thumbnail:(video.thumbnail_url || noVideo)}
        style={{
          objectFit: video.video_type === "landscape" ? "cover" : "scale-down",
        }}
      />
    </div>
  );
};

export default ThumbnailComponent;
