import RichTextEditor from ".";
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { countChar, isRichTextEmpty } from "../../utils/editor";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { videoService } from "../../services/video.service.ts";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import { updateNoteContent } from "../../actions/data";
import TooltipComponent from "../TooltipComponent/TooltipComponent.js";

const RichTextEditorComponent = ({
  title,
  setContent = () => {},
  object = null,
  type = null,
  handleNoteSaved = () => {},
  placeholder = "Write a description...",
}) => {
  /*
        RichTextEditor component for input & styling of video, playlist, and profile descriptions. 
        Also handles notes in FocusCard and MyNotes page.
    */
  const dispatch = useDispatch();
  const [editor, setEditor] = useState(null);
  const [isCmdPressed, setIsCmdPressed] = useState(false);
  const [description, setDescription] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [renders, setRenders] = useState(0);
  const [initialVal, setInitialVal] = useState();
  const [saving, setSaving] = useState("Saved");

  const updateNote = () => {
    let contentVal=JSON.stringify(description)
    if (isRichTextEmpty(description)) {
      contentVal = "0";
    }
    const dataToSend = {
      video: object.id,
      video_id: object.id,
      content: contentVal,
    };

    videoService
      .updateNote(dataToSend)
      .then(() => {
        dispatch(updateNoteContent(object.id, JSON.stringify(description)));
        if (type === "notesPage") {
          dispatch(notifySuccess("Note saved successfully"));   
        }
        setSaving("Saved")
      })
      .catch((error) => {
        console.error("Error adding note:", error);
        if (type === "notesPage") {
          dispatch(notifyError("Error adding note"));
        }
        setSaving("Error saving note")
      });
  };

  useEffect(() => {
    if (object && (type === "notes") && renders > 0) {
      setSaving("Saving ...");
      let timeoutId;
      timeoutId = setTimeout(() => {
        if (
          description!==initialVal
        ) {
          updateNote();
          setInitialVal(description)
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [description]);

  useEffect(() => {
    if (object && !type) {
      let intialDesc = object.description;
      if ("introduction" in object) {
        intialDesc = object.introduction;
      }
      try {
        intialDesc = JSON.parse(intialDesc);
      } catch (err) {
        intialDesc = {
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [{ type: "text", text: intialDesc }],
            },
          ],
        };
        setContent(intialDesc);
      }
      const initialTextLength = countChar(intialDesc);
      setCharCount(initialTextLength);
      setDescription(intialDesc);
    }
  }, [object]);

  useEffect(() => {
    if (object && (type === "notes" || type === "notesPage")) {
      const notes = object.notes[0];
      if (!!notes && !!notes.content) {
        let transformedNote = notes.content;
        if (typeof notes.content === "string") {
          transformedNote = JSON.parse(notes.content);
        }
        setDescription(transformedNote);
        setInitialVal(transformedNote)
      }
    }
  }, []);

  const handleChange = (event) => {
    // Check for renders, bcs the first render updates the editor to be empty
    if (renders > 0) {
      setDescription(event);
      setContent(event);
      if (!type) {
        const textLength = countChar(event);
        setCharCount(textLength);
      }
    }
    setRenders(renders + 1);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.keyCode === 91 ||
        event.keyCode === 93 ||
        event.keyCode === 17
      ) {
        setIsCmdPressed(true);
      }

      if (event.keyCode === 13 && isCmdPressed) {
        event.preventDefault();
      }
    };

    const handleKeyUp = (event) => {
      if (
        event.keyCode === 91 ||
        event.keyCode === 93 ||
        event.keyCode === 17
      ) {
        setIsCmdPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isCmdPressed]);

  return (
    <div
      className="editor"
      style={{ padding: type != null ? "24px 20px" : "0px" }}
    >
      <div className="notes-wrapper">
        <div className="editor-title">
          {title}
          {type === null && (
            <TooltipComponent
              tipText={
                "This is a rich text editor. You can use Markdown formatting syntax."
              }
              longText={true}
            />
          )}
        </div>
        <RichTextEditor
          content={description}
          setNoteData={handleChange}
          setEditor={setEditor}
          disableMenu={type !== null}
          placeholder={type?.includes("notes") ? "Add a note..." : placeholder}
          isIncreasedHeight={type === "notes"}
        />

        {type === null ? (
          <div className={`char-count ${charCount > 500 ? "long" : "normal"}`}>
            {charCount}/500
          </div>
        ) : (
            type === "notesPage" ? (
              <div className="note-options">
              <div className="note-options-button">
                <ButtonComponent
                  text="Cancel"
                  icon={false}
                  size="small"
                  level="secondary"
                  handleClick={handleNoteSaved}
                  coverFullWidth={true}
                />
              </div>
            
            <div className="note-options-button">
              <ButtonComponent
                text="Save note"
                icon={false}
                size="small"
                level={initialVal===description ? "disable" : "primary"}
                coverFullWidth={true}
                handleClick={() => {
                  updateNote();
                  handleNoteSaved();
                }}
              />
            </div>
          </div>)
          : 
          <div className="saving-text">
            <p>{saving}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default RichTextEditorComponent;
