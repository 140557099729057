import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthPage from "./pages/AuthPage/AuthPage.js";
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordConfirm from "./containers/ResetPasswordConfirm";
import PrivatePage from "./utils/PrivatePage";
import FeedWrapper from "./containers/FeedWrapper";
import Dashboard from "./pages/Dashboard/Dashboard";
import Layout from "./hocs/Layout";
import "./app.scss";
import Toast from "./components/Toast";
import EditVideo from "./pages/EditVideo/EditVideo";
import UploadPopUpPage from "./pages/UploadPopUpPage/UploadPopUpPage";
import LandingPage from "./pages/LandingPage/LandingPage.js";
import LandingPageRegistered from "./pages/LandingPageRegistered/LandingPageRegistered.js";
import SearchPage from "./pages/SearchPage/SearchPage.js";
import CreatorPage from "./pages/CreatorPage/CreatorPage.js";
import Settings from "./pages/Settings/Settings";
import BlogPage from "./pages/BlogPage/BlogPage";
import MdPage from "./pages/MdPage/MdPage";
import SocialRedirectPage from "./pages/SocialRedirectPage/SocialRedirectPage.js";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage.js";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PrivatePage guestAccessible>
                <LandingPage />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/registered"
            element={
              <PrivatePage guestAccessible>
                <LandingPageRegistered />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/search"
            element={
              <PrivatePage guestAccessible>
                <SearchPage />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/feed"
            element={
              <PrivatePage guestAccessible>
                <FeedWrapper type="feed" />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/upload"
            element={
              <PrivatePage>
                <UploadPopUpPage />
              </PrivatePage>
            }
          />
          {/* Commenting this out since we don't use this page. But we can use it in the future */}
          {/*
          <Route
            exact
            path="/playlists"
            element={
              <PrivatePage>
                <MyPlaylists />
              </PrivatePage>
            }
          /> */}
          <Route
            exact
            path="/dashboard/"
            element={
              <PrivatePage>
                <Dashboard />
              </PrivatePage>
            }
          />
          <Route
            path="/video/:videoUuid"
            element={
              <PrivatePage guestAccessible>
                <FeedWrapper type="video" />
              </PrivatePage>
            }
          />
          <Route
            path="/channel/:channelUsername"
            element={
              <PrivatePage guestAccessible>
                <CreatorPage />
              </PrivatePage>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivatePage>
                <Settings />
              </PrivatePage>
            }
          />
          <Route
            path="/blog/:filename"
            element={
              <PrivatePage guestAccessible>
                <BlogPage />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/pages/:filename"
            element={
              <PrivatePage guestAccessible>
                <MdPage />
              </PrivatePage>
            }
          />
          <Route
            path="/playlist/:playlistUuid"
            element={
              <PrivatePage guestAccessible>
                <FeedWrapper type="playlist" />
              </PrivatePage>
            }
          />
          <Route exact path="/login" element={<AuthPage />} />
          <Route exact path="/signup" element={<AuthPage />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/password/reset/confirm/:uid/:token"
            element={<ResetPasswordConfirm />}
          />
          <Route
            exact
            path="/edit/:videoUuid"
            element={
              <PrivatePage>
                <EditVideo />
              </PrivatePage>
            }
          />
          <Route
            exact
            path="/social-auth"
            element={
              <PrivatePage guestAccessible>
                <SocialRedirectPage />
              </PrivatePage>
            }
          />
          {/* <Route exact path='/activate/:uid/:token' component={Activate} /> */}
          <Route
            path="*"
            element={
              <PrivatePage guestAccessible>
                <NotFoundPage />
              </PrivatePage>
            }
          />
        </Routes>
      </Layout>
      <Toast />
    </Router>
  );
};

export default App;
