import { SET_CURRENT_CARD } from "../actions/types";

const initialState = {
  currentCard: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CARD:
      return {
        ...state,
        currentCard: action.payload,
      };
    default:
      return state;
  }
};

export default cardReducer;
